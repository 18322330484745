import {Component, Vue} from 'vue-property-decorator';
import {EvaluationType} from "../../shared/model/EvaluationType";
import {ProviderType} from "../../shared/model/ProviderType";
import {ActivityType} from "../../shared/model/ActivityType";
import {County} from "../../shared/model/County";
import {SurveyType} from "../../shared/model/SurveyType";
import {Status} from "../../shared/model/Status";
import {evalApi} from "../../plugins/axios";
import {FiscalYear} from "../../shared/model/FiscalYear";
import {Department} from "../../shared/model/Department";
import {Flag} from "@/shared/model/Flag";
import {SurveyFundingPercent} from "@/shared/model/SurveyFundingPercent";
import {FundingType} from "@/shared/model/FundingType";
import {rules} from "@/shared/model/Rules";

@Component({
    components: {},
})
export default class SurveyFundingPercentForm extends Vue {
    title = "Survey Funding Percent Form"
    maskText = "";
    valid = true;
    surveyFundingPercent: SurveyFundingPercent = new SurveyFundingPercent();
    menu1 = false;
    menu2 = false;
    evaluationTypes = EvaluationType.values;
    providerTypes = ProviderType.values;
    activityTypes = ActivityType.values;
    counties = County.values;
    surveyTypes: Array<SurveyType> = [];
    fundingTypes: Array<FundingType>= [];
    statuses = Status.values;
    stateFlags = Flag.values;
    fiscalYears = new FiscalYear().values
    endDateString: string = "";
    dueDateString: string = "";
    departments = Department.values.sort();
    fundingTypeIds: any = [];
    surveyTypeIds:any = [];
    requiredRule = rules;
    snackbar = {
        visible: false,
        color: "success",
        text: ""
    };

    public created() {
        this.loadFundingTypes();
        this.loadSurveyTypes();
    }

    formatDate(date: String): String {
        if (!date) return "MM/DD/YYYY";
        const [year, month, day] = date.split('-')
        return `${month}/${day}/${year}`
    }

    async submitNewSurveyFundingPercent(): Promise<void> {
        const res = await evalApi.get(`/surveyFundingPercent/exists/` + this.surveyFundingPercent.surveyTypeId
            + '/' + this.surveyFundingPercent.fundingTypeId
            + '/' + this.surveyFundingPercent.federalFiscalYear)
        if (res.data) {
            this.snackbar.color = "error"
            this.snackbar.text = "A record for this survey type ID, funding type ID and federal fiscal year already exists. No duplicates are allowed."
            this.snackbar.visible = true
            return
        }

        evalApi.post("/surveyFundingPercent/create", {
            fundingTypeId: this.surveyFundingPercent.fundingTypeId,
            surveyTypeId: this.surveyFundingPercent.surveyTypeId,
            budgetDepartment: this.surveyFundingPercent.budgetDepartment,
            federalFiscalYear: this.surveyFundingPercent.federalFiscalYear,
            startDate: this.surveyFundingPercent.startDate,
            endDate: this.surveyFundingPercent.endDate,
            percent: this.surveyFundingPercent.percent
        }).then(res => {
            this.snackbar.color = "success"
            this.snackbar.text = "Survey funding percent type has been created."
            this.snackbar.visible = true
        }).catch(error => {
            this.snackbar.color = "error"
            this.snackbar.text = "An error occurred updating the survey funding percent type: >>>> " + error
            this.snackbar.visible = true
        })
    }

    public loadFundingTypes(): void {
        evalApi.get('/fundingType')
            .then((res: any) => {
                this.fundingTypes = res.data;
                for (let i:number = 0; i <= this.fundingTypes.length; i++) {
                    this.fundingTypeIds.push(this.fundingTypes[i].fundingTypeId)
                }
            })
        this.fundingTypeIds.sort()
    }

    public loadSurveyTypes(): void {
        evalApi.get('/surveyType/all')
            .then((res: any) => {
                this.surveyTypes = res.data;
                for (let i:number = 0; i <= this.surveyTypes.length; i++) {
                    this.surveyTypeIds.push(this.surveyTypes[i].surveyTypeId)
                }
            })
        this.surveyTypeIds.sort()
    }
}